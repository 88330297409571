.personal-img{
    box-shadow: 5px 5px 10px #460353,
                -5px -5px 10px #0051bbbb;
    max-width: 700px;
}

#front-head{
    text-shadow: 3px 3px 3px #460353;
}
.cont-head{
    text-shadow: 2px 2px 3px #460353;
}
#chris-bitmoji{
    image-orientation: 45deg;
}
.sec-2{
    height: 89px;
}
.sec-2-dsk{
    height: 145px;
}
.intro{
    text-shadow: 1px 1px 1px #2b7e25;
}
.about-section{
    margin-top: 950px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #d369e9;
}
.about-desktop{
    margin-top: 850px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #d369e9;
}
.about-tablet{
    margin-top: 1250px;
}
.blue-tablet{
    height: 700px;
}
.blue-bg{
    height: 52em;
}
.blue-bg-tab{
    height: 30em;
}
.blue-bg-dsk{
    height: 54em;
}
.purple-bg{
    height: 15em;
}
.purple-bg-tab{
    height: 20em;
}
.purple-bg-dsk{
    height: 25em;
}
hr{
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    height: 3px;
    width: 66%;
    background-color: #860fbd;
    border-radius: 5px;

}
#mobile-button{
    background-color: rgb(167, 243, 208);
    border-radius: 40px;
    padding: 10px;
    color: rgb(29, 78, 216);
    border: 1px solid rgb(106, 0, 148);
    font-size: 1.5em;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: inset 1px 1px 3px rgba(92, 14, 128, 0.39), 2px 2px 2px rgba(0, 0, 0, 0.39);
}

 /* inViewport */
  
 [data-inviewport="scale-in"] { 
    transition: 1s;
    transform: scale(.95);
  }
  [data-inviewport="scale-in"].is-inViewport { 
    transform: scale(1);
  }
  
  [data-inviewport="fade-rotate"] { 
    transition: 2s;
    opacity: 0;
  }
  [data-inviewport="fade-rotate"].is-inViewport { 
    transform: rotate(180deg);
    opacity: 1;
  }