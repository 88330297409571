.contact-main{
    background: linear-gradient(to left, rgb(0, 68, 255), rgb(4, 27, 92));
    height: 68.4px;
}
#mobile-con{
    font-size: 50px;
    -webkit-text-stroke: 1px black;
    color: rgba(0, 0, 255, 0.664);
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.301);
}
input{
    box-shadow: 3px 3px 3px rgba(0, 3, 194, 0.404);
    border: 1px solid blue;
    width: 100%;
}
textarea{
    resize: none;
    box-shadow: 3px 3px 3px rgba(0, 3, 194, 0.404);
    border: 1px solid blue;
}
.mob-but{
    background-color: rgb(167, 243, 208);
    padding: 10px;
    border: 1px solid blue;
    color: blue;
    box-shadow: 3px 3px 3px rgba(0, 0, 255, 0.562);
}
.dsk-but{
    background-color: rgb(167, 243, 208);
    padding: 15px;
    border: 1px solid blue;
    color: blue;
    box-shadow: 3px 3px 3px rgba(0, 0, 255, 0.562);
    font-size: 20px;
}
#hr-mob-full{
    width: 90%;
    margin-top: 8em;
}
#hr-mob-dsk{
    width: 70%;
    margin-top: 8em;
}

 /* inViewport */
  
 [data-inviewport="scale-in"] { 
    transition: 1s;
    transform: scale(.8);
  }
  [data-inviewport="scale-in"].is-inViewport { 
    transform: scale(1);
  }
  
  [data-inviewport="fade-rotate"] { 
    transition: 2s;
    opacity: 0;
  }
  [data-inviewport="fade-rotate"].is-inViewport { 
    transform: rotate(180deg);
    opacity: 1;
  }