.ani{
    animation: header 1s ;
}
.nav-menu {
    transition: all .7s ease-in-out;
}

#menu_checkbox
{
    display: none;
}

label
{
    position: relative;
    top: 50%;
    right: 0;
    left: 0;
    margin: 0 auto;
    margin-top: 1.4em;
    margin-bottom: 0;
    display: block;
    width: 30px;
    height: 30px;
    transform: translateY(-50%);
    transition: 0.3s ease transform;
    cursor: pointer;
}

label div
{
    width: 6px;
    height: 6px;
    background-color: #1ccaff;
    margin-left: 0;
    margin-bottom: 6px;
    border-radius: 4px;
    transition: 0.3s ease width, 0.3s ease margin-left, 0.3s ease margin-bottom, 0.3s ease background-color;
}

label div:first-child
{
    width: 22px;
}

label div:nth-child(2)
{
    width: 15px;
}

label div:last-child
{
    width: 30px;
    margin-bottom: 0;
}

#menu_checkbox:checked + label
{
    transform: translateY(-50%) rotateZ(-90deg);
}

#menu_checkbox:checked + label div
{
    width: 6px;
    margin-left: 12px;
    margin-bottom: 3px;
    background-color: #d30000;
}

.NavbarItems{
    position: fixed;
    z-index: 2;
    top: 0;
}
#nav-head{
    font-size: 12vw;
    color: red;
    background-color: red;
}
.nav-header{
    animation: header 1s ;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.445);
}
@keyframes header {
    0% {
        transform: translateY(30%);
        
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes menu {
    0% {
        transform: rotateX(360deg);
    }
    100% {
        transform: rotateX(0);
    }
}
.nav-link{
    animation: slidein .7s ease-in;
}
.nav-link:nth-child(1){
    animation-delay: .1s;
}
.nav-link:nth-child(2){
    animation-delay: .2s;
}
.nav-link:nth-child(3){
    animation-delay: .3s;
}

@keyframes slidein {
    0%{
        margin-right: -800px;
        width: 50%;
        border: none;
    }
    20%{
        margin-right: -800px;
        width: 50%;
    }
    35%{
        margin-right: 0px;
        width: 100%;
    }
}
