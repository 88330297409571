#dt{
    border: 8px outset rgb(17, 17, 124);
    box-shadow: 4px 4px 4px rgb(17, 17, 124, 0.5);
    
}
#mob-btn{
    padding: 10px;
    box-shadow: 2px 2px 2px rgb(17, 17, 124, 0.5);
}
#tab-btn{
    padding: 10px;
    box-shadow: 2px 2px 2px rgb(17, 17, 124, 0.5);
}
.dt-btn{
    padding: 10px;
    box-shadow: 2px 2px 2px rgb(17, 17, 124, 0.5);
}
#mob-h{
    text-shadow: 2px 2px 2px rgba(103, 17, 124, 0.5);
}
#tab-h{
    text-shadow: 2px 2px 2px rgba(103, 17, 124, 0.5);
}
#dt-h{
    text-shadow: 2px 2px 2px rgba(103, 17, 124, 0.5);
}


  
  /* inViewport */
  
  [data-inviewport="scale-in"] { 
    transition: 1s;
    transform: scale(0.4);
  }
  [data-inviewport="scale-in"].is-inViewport { 
    transform: scale(1);
  }
  
  [data-inviewport="fade-rotate"] { 
    transition: 2s;
    opacity: 0;
  }
  [data-inviewport="fade-rotate"].is-inViewport { 
    transform: rotate(180deg);
    opacity: 1;
  }