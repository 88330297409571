@import url(https://fonts.googleapis.com/css?family=Gilda+Display);
.nav-bg-1{
    height: 68.4px;
}
.section-1{
    height: 75vh;
}

/* 404 Animation */
.error{
    font-family: 'Gilda Display', serif;
    font-size: 40px;
    color: #fff;
    text-align: center;
    animation: noise 1s linear infinite;
    color: blue;
}
.error::before{
    content: '404';
    animation: noise-2 1s ease-in-out infinite;
    color: purple;
    position: absolute;
}
.error::after{
    content: '404';
    font-family: 'Gilda Display', 'serif';
    animation: noise-1 1s linear infinite;
    position: absolute;
}
@keyframes noise-1 {
    0%, 20%, 40%, 60%, 70%, 90% {opacity: 0;}
    10% {opacity: .1;}
    50% {opacity: .5; left: -6px;}
    80% {opacity: .3;}
    100% {opacity: .6; left: 2px;}
  }
  
  @keyframes noise-2 {
    0%, 20%, 40%, 60%, 70%, 90% {opacity: 0;}
    10% {opacity: .1;}
    50% {opacity: .5; left: 3px;}
    80% {opacity: .3;}
    100% {opacity: .6; left: -2px;}
  }
  
  @keyframes noise {
    0%, 3%, 5%, 42%, 44%, 100% {opacity: 1; transform: scaleY(1);}  
    4.3% {opacity: 1; transform: scaleY(1.7);}
    43% {opacity: 1; transform: scaleX(1.5);}
  }
  
  @keyframes noise-3 {
    0%,3%,5%,42%,44%,100% {opacity: 1; transform: scaleY(1);}
    4.3% {opacity: 1; transform: scaleY(4);}
    43% {opacity: 1; transform: scaleX(10) rotate(60deg);}
  }
.four-1{
    margin-top: 4em;
    letter-spacing: 10px;
}
#link-home-1{
    background-color: rgb(167, 243, 208);
    border-radius: 40px;
    padding: 10px;
    color: rgb(29, 78, 216);
    border: 1px solid rgb(106, 0, 148);
    font-size: 1.5em;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: inset 1px 1px 3px rgba(92, 14, 128, 0.39), 2px 2px 2px rgba(0, 0, 0, 0.39);
}